// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root {
    font-size: clamp(8px, 2vw, 24px);

    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;

    margin: 0;
    
    background-color: #212121;
}
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAGA;;;IAGI,gCAAgC;;IAEhC,YAAY;IACZ,WAAW;;IAEX,aAAa;IACb,uBAAuB;;IAEvB,SAAS;;IAET,yBAAyB;AAC7B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap');\n\nhtml,\nbody,\n#root {\n    font-size: clamp(8px, 2vw, 24px);\n\n    height: 100%;\n    width: 100%;\n\n    display: flex;\n    justify-content: center;\n\n    margin: 0;\n    \n    background-color: #212121;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
