import {Routes, Route} from 'react-router-dom';
import Main from './pages/Main';
import Data from './pages/Data';
import Error from './pages/Error';

function App() {
    return (
        <Routes>
            <Route path="/" element={<Main />}></Route>
            <Route path="/error" element={<Error />}></Route>
            <Route path="/i/:urlCode" element={<Data />}></Route>
        </Routes>
    );
}

export default App;
