import styled from 'styled-components';
import Header from '../components/Header';
import UrlMain from '../components/UrlMain';

const MainContainer = styled.div`
    height: 100%;
    width: calc(100vw - 2rem);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Copyrights = styled.h5 `
    color: white;
    margin: 0;
    margin-top: 1rem;
    font-size: 0.5rem;
`;

function Main() {
    return (
        <MainContainer>
            <Header/>
            <UrlMain/>
            <Copyrights>Copyrightⓒ2024 Kineic27 All rights reserved.</Copyrights>
        </MainContainer>
    );
}

export default Main;
