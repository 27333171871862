import {useState} from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import {useParams} from 'react-router-dom';
import axios from 'axios';

const MainContainer = styled.div`
    height: 100%;
    width: calc(100vw - 2rem);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
`;

const VisitText = styled.h3`
    font-size: 4rem;
    margin: 1rem 0;
`;

const UrlInfo = styled.h4`
    margin: 0;

    & > a {
        color: inherit;
        text-decoration: underline;
    }
`;

const Restart = styled.button`
    margin-top: 1rem;
    font-size: 2rem;
    padding: 1rem 2rem;
    border: solid 0.3rem white;
    background-color: transparent;
    border-radius: 4rem;
    color: white;
    cursor: pointer;
`;

const Data = () => {
    const [urlData, setUrlData] = useState({
        shorter: 'null',
        url: 'https://url.kinetic.moe/',
        creation_time: '2024-04-30',
        expiration_time: '2024-04-30',
        visit: 1,
    });

    const {urlCode} = useParams();
    axios
        .get(`https://url.kinetic.moe/api/url/${urlCode}+`)
        .then((res) => {
            setUrlData(res.data);
        })
        .catch((e) => console.log('error wow wtf:\n' + e));

    const c_time = new Date(urlData.creation_time);
    const e_time = new Date(urlData.expiration_time);

    return (
        <MainContainer>
            <Header />
            <VisitText>조회수: {urlData.visit}</VisitText>
            <UrlInfo>
                URL 생성일:{' '}
                {new Date(c_time.getTime() + 9 * 60 * 60 * 1000).toISOString().replace('T', ' ').slice(0, -5)}
            </UrlInfo>

            <UrlInfo>
                URL 만료일:{' '}
                {new Date(e_time.getTime() + 9 * 60 * 60 * 1000).toISOString().replace('T', ' ').slice(0, -5)}
            </UrlInfo>

            <UrlInfo>
                원본 URL:{' '}
                <a href={/^https?:\/*/i.test(urlData.url) ? urlData.url : 'https://' + urlData.url}>{urlData.url}</a>
            </UrlInfo>

            <Restart onClick={() => window.location.replace(window.location.origin)}>
                {' '}
                {'> 다른 URL 압축하기! <'}{' '}
            </Restart>
        </MainContainer>
    );
};

export default Data;
