import styled from 'styled-components';
import Header from '../components/Header';

const MainContainer = styled.div`
    height: 100%;
    width: calc(100vw - 2rem);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ErrorText = styled.h2`
    color: white;
    font-size: 5rem;
`;

const Restart = styled.button`
    font-size: 2rem;
    padding: 1rem 2rem;
    border: solid 0.3rem white;
    background-color: transparent;
    border-radius: 4rem;
    color: white;
    cursor: pointer;
`;

const Error = () => {
    return (
        <MainContainer>
            <Header />
            <ErrorText>잘못된 URL입니다!!</ErrorText>
            <Restart onClick={() => window.location.replace(window.location.origin)}> {'> 다시하기! <'} </Restart>
        </MainContainer>
    );
};

export default Error;
