import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Container = styled.div`
    margin-top: 3rem;
`;

const RegisterBox = styled.form`
    min-width: 45.5rem;
    display: flex;
    justify-content: center;

    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
        height: 8rem;
    }
`;

const UrlBox = styled.input`
    flex-grow: 1;
    font-size: 1.2rem;
    background-color: white;
    border: none;
    outline: none;
    padding: 0 1rem;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
`;

const SubmitButton = styled.button`
    width: 8rem;
    padding: 1rem 0;

    background-color: #304ffe;
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Noto Sans KR', sans-serif;
    border: none;

    cursor: pointer;

    &:hover {
        background-color: #2a49fc;
    }
`;

const InfoText = styled.h5`
    margin: 0;
    padding: 0;
    margin-top: 0.1rem;

    font-family: 'Noto Sans KR', sans-serif;
    color: white;
    display: inline;
    font-size: 1rem;
    cursor: pointer;

    @media (max-width: 768px) {
        margin-top: 0.5rem;
        font-size: 3rem;
    }
`;

function UrlMain() {
    const [url, setUrl] = useState('');
    const [canEdit, setCanEdit] = useState(true);
    const [info, setInfo] = useState('압축된 링크 뒤에 + 를 붙여 통계를 볼 수 있어요!');

    const inputRef = useRef(null);

    const urlChange = (event) => {
        setUrl(event.target.value);
    };

    const onSubmitPress = (event) => {
        event.preventDefault();

        if (event.key === 'Enter') {
            submit();
        }
    };

    function submit() {
        var isValidUrl = /\S+\.\S+/;

        // 나중에 메시지 띄우는거 추가
        if (!url.trim()) return;
        if (!isValidUrl.test(url)) {
            setInfo('> 올바른 URL을 입력해주세요! <');
            return;}

        console.log('url:' + url);

        axios
            .post('https://url.kinetic.moe/api/url', {url: url})
            .then((res) => {
                setCanEdit(false);

                inputRef.current.value =
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    '/' +
                    window.location.pathname.slice(0, -1) +
                    res.data.url;

                inputRef.current.select();
                copy();
                setInfo('> 다른 URL 압축하기 <');
                // window.location.replace('/');
            })
            .catch((e) => console.log('error wow wtf:\n' + e));
    }

    function copy() {
        // clipboard API 사용
        if (navigator.clipboard !== undefined) {
            navigator.clipboard.writeText(inputRef.current.value);
        } else {
            // execCommand 사용
            const textArea = document.createElement('textarea');
            textArea.value = inputRef.current.value;
            document.body.appendChild(textArea);
            textArea.select();
            textArea.setSelectionRange(0, 99999);
            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('복사 실패', err);
            }
            textArea.setSelectionRange(0, 0);
            document.body.removeChild(textArea);
        }
    }

    function showInfo() {
        setCanEdit(true);
        setInfo('압축된 링크 뒤에 + 를 붙여 통계를 볼 수 있어요!');
        inputRef.current.value = '';
    }

    return (
        <Container>
            <RegisterBox onSubmit={onSubmitPress}>
                <UrlBox
                    placeholder="압축할 URL을 여기에 입력"
                    defaultValue={url}
                    ref={inputRef}
                    onChange={urlChange}
                    readOnly={canEdit ? false : true}
                />
                <SubmitButton onClick={canEdit ? submit : copy}>{canEdit ? '압축!' : '복사!'}</SubmitButton>
            </RegisterBox>

            <InfoText onClick={showInfo}>{info}</InfoText>
        </Container>
    );
}

export default UrlMain;
