import styled from 'styled-components';

const HeaderConatiner = styled.div`
    min-width: 48rem;

    display: flex;
    justify-content: center;
`;

const UrlTitle = styled.h1`
    font-size: 7rem;
    color: white;
    font-family: 'Black Han Sans', sans-serif;
    font-weight: 400;
    word-break:keep-all;

    margin: 0;
`;

const Header = () => (
    <HeaderConatiner>
        <UrlTitle>URL 유압프레스</UrlTitle>
    </HeaderConatiner>
);

export default Header;
